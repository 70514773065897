<template>
  <div>
    <v-row>
      <v-col md="12">
        <WhatsApp></WhatsApp>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12">
        <MaintenanceMode></MaintenanceMode>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import WhatsApp from './WhatsApp.vue';
import MaintenanceMode from './MaintenanceMode.vue';
export default {
  components: {
    WhatsApp,
    MaintenanceMode,
  },

  mounted() {
    this.requiredLogin();
  },
};
</script>