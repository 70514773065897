<template>
  <div>
    <v-card :loading="loading" elevation="0">
      <v-card-title>Whats App</v-card-title>
      <v-divider></v-divider>

      <v-tabs>
        <v-tab @click="selectedWATab = 0"> WA1 </v-tab>
        <v-tab @click="selectedWATab = 1"> WA2 </v-tab>
        <v-tab @click="selectedWATab = 2"> WA3 </v-tab>
      </v-tabs>
      <v-card-text>
        <v-tabs-items v-model="selectedWATab">
          <v-tab-item>
            <v-text-field v-model="settings.contact_wa1_number" label="WA No."></v-text-field>
            <v-text-field v-model="settings.contact_wa1_name" label="Name"></v-text-field>
            <v-select v-model="settings.contact_wa1_active" label="Active" :items="['Y', 'N']"></v-select>
          </v-tab-item>
          <v-tab-item>
            <v-text-field v-model="settings.contact_wa2_number" label="WA No."></v-text-field>
            <v-text-field v-model="settings.contact_wa2_name" label="Name"></v-text-field>
            <v-select v-model="settings.contact_wa2_active" label="Active" :items="['Y', 'N']"></v-select>
          </v-tab-item>
          <v-tab-item>
            <v-text-field v-model="settings.contact_wa3_number" label="WA No."></v-text-field>
            <v-text-field v-model="settings.contact_wa3_name" label="Name"></v-text-field>
            <v-select v-model="settings.contact_wa3_active" label="Active" :items="['Y', 'N']"></v-select>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  components: {
  },
  data() {
    return {
      loading: false,
      dialog: false,
      search: "",
      settings: {},
      selectedWATab: 1,
    };
  },

  methods: {
    async getData() {
      this.loading = true
      await this.$axios
        .get("setting/data")
        .then((res) => {
          var resData = res.data;
          if (resData.status == "success") {
            this.settings = resData.data;
          } else {
            this.settings = {};
          }
          this.loading = false
        })
        .catch((error) => {
          this.axiosErrorHandler(error);
          this.settings = {};
          this.loading = false
        });
    },

    initValue() {
      this.getData();
    },
  },

  mounted() {
    this.requiredLogin();
    this.initValue();
  },
};
</script>