<template>
  <div>
    <v-card :loading="loading" elevation="0">
      <v-card-title>Maintenance Mode</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title>Maintenance Mode BPW PORTAL</v-list-item-title>
            <v-list-item-subtitle>
              <p class="text-help">Disable access to BPW PORTAL for standard user!</p>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-switch v-model="app_bpw_maintenance_mode" :loading="loading" @change="updateSetting" value="Y"></v-switch>
          </v-list-item-action>
        </v-list-item>
      </v-card-text>
      <v-card-actions>

      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      app_bpw_maintenance_mode: null,
      value_change: null,
    }
  },
  watch: {
  },
  methods: {
    async updateSetting() {
      this.loading = true;
      const uri = 'setting/set-value';
      let formData = new FormData();
      formData.append("name", "app_bpw_maintenance_mode");
      formData.append("value", this.app_bpw_maintenance_mode);
      await this.$axios.post(uri, formData)
        .then((res) => {
          var resData = res.data;
          if (resData.status == "success") {
            this.app_bpw_maintenance_mode = resData.data.value;
          } else {
            this.app_bpw_maintenance_mode = null;
          }
          this.loading = false
        })
        .catch((error) => {
          this.axiosErrorHandler(error);
          this.app_bpw_maintenance_mode = null;
          this.loading = false
        });
    },
    async getSetting() {
      this.loading = true;
      await this.$axios.get("setting/get-value", {
          params: {
            name: "app_bpw_maintenance_mode"
          }
        })
        .then((res) => {
          if (res.data && res.data.data !== null) {
            this.app_bpw_maintenance_mode = res.data.data.value;
          }

          this.loading = false;
        })
        .catch((error) => {
          this.axiosErrorHandler(error);
          this.loading = false
        });
    }
  },
  mounted() {
    this.getSetting();
  }
}
</script>