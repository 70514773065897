<template>
  <div>
    <v-card flat elevation="0">
      <v-toolbar dense elevation="0">
        <div style="max-width: 100px">
          <v-select :items="itemsPerPageOption" label="Show Items" dense v-model="datatable_options.itemsPerPage" style="max-width: 100px" hide-details single-line></v-select>
        </div>

        <v-spacer></v-spacer>

        <v-text-field v-if="!isMobile" dense autofocus outlined rounded placeholder="Search" v-model="search" append-icon="mdi-magnify" style="max-width: 300px" hide-details single-line></v-text-field>

        <v-btn icon @click="getData(true)" title="Refresh">
          <v-icon>mdi-sync</v-icon>
        </v-btn>
      </v-toolbar>

      <div class="px-2" v-if="isMobile">
        <v-text-field class="mb-2" dense clearable autofocus outlined placeholder="Search" v-model="search" append-icon="mdi-magnify" style="max-width: 3000px" hide-details single-line></v-text-field>
      </div>

      <v-data-table :options.sync="datatable_options" :loading="datatable.loading" :loading-text="datatable.loadingText" :headers="datatable.columns" :items="datatable.data" :search="search" :hide-default-header="isMobile" :server-items-length="datatable.totalRows" :items-per-page="datatable_options.itemsPerPage" dense class="mb-3 my-0" hide-default-footer @page-count="pageCount = $event">
        <template v-slot:[`item.is_active`]="{ item }" v-if="!isMobile">
          <v-btn icon text x-small class="mx-1" color="green" @click="setActive(item, false)" v-if="item.is_active"><v-icon>mdi-check</v-icon></v-btn>
          <v-btn icon text x-small class="mx-1" color="primary" @click="setActive(item, true)" v-else><v-icon>mdi-cancel</v-icon></v-btn>
        </template>
        <template v-slot:[`item.action`]="{ item }" v-if="!isMobile">
          <v-btn icon text x-small class="mx-1" color="primary" @click="editData(item.id)"><v-icon>mdi-pencil</v-icon></v-btn>
        </template>

        <v-alert slot="no-results" :value="true" color="error" icon="warning">Your search for "{{ search }}" found no results.</v-alert>

      </v-data-table>
      <div class="text-right pt-2 mx-auto" style="max-width: 600px">
        <v-pagination v-model="datatable_options.page" :length="totalPage"></v-pagination>
      </div>
    </v-card>

    <ModuleEditUser ref="refModuleEditUser"></ModuleEditUser>
  </div>
</template>

<script>
import ModuleEditUser from './ModuleEditUser.vue';
export default {
  components: {
    ModuleEditUser,
  },
  props: {},
  data() {
    return {
      dialog_detail: null,
      loading: null,
      search: "",
      datatable_options: {},
      itemsPerPageOption: [10, 20, 50, 100, 500],
      totalPage: 0,
      datatable: {
        loading: null,
        loadingText: "Loading... Please wait...",
        totalRows: 0,
        columns: [
          { text: "ID", value: "id", class: "text-no-wrap" },
          { text: "Name", value: "name", class: "text-no-wrap" },
          { text: "Description", value: "description", class: "text-no-wrap" },
          { text: "Is Active", value: "is_active", class: "text-no-wrap" },
          { text: "#", value: "action", name: "action", class: "text-no-wrap", searchable: false, sortable: false },
        ],
        data: [],
      },

      selectedItem: {},

      accessType: null,
    };
  },

  watch: {
    datatable_options: {
      handler() {
        this.getData();
      },
      deep: true,
    },

    search: {
      handler() {
        this.datatable_options.page = 1;
        this.getData();
      },
      deep: true,
    },
  },

  methods: {
    refreshData() {
      this.getData(true);
    },
    getData(refresh) {
      if (refresh) this.AxiosStorageRemove("POST", "module/data");

      const { sortBy, sortDesc, page, itemsPerPage } = this.datatable_options;

      this.datatable.loading = true;
      var formData = new FormData();
      formData.append("search", this.search);
      formData.append("sort_by", sortBy);
      formData.append("sort_desc", sortDesc);
      formData.append("page", page);
      formData.append("limit", itemsPerPage);

      this.$axios
        .post("module/data", formData)
        .then((res) => {
          var resData = res.data;
          if (resData.status == "success") {
            this.datatable.data = resData.data.results;
            this.datatable.totalRows = resData.data.paging.total_rows;
            this.totalPage = resData.data.paging.total_page;
          } else {
            this.showAlert(resData.status, resData.message);
          }
          this.datatable.loading = false;
        })
        .catch((error) => {
          this.axiosErrorHandler(error);
          this.datatable.loading = false;
        });
    },
    editData(moduleId){
      this.redirect("Setting.General.ModuleEdit", { id: moduleId });
    }

  },

  mounted() {
    this.getData();
  },
};
</script>

<style scoped>
tr .show-on-hover button {
  display: none;
}

tr:hover .show-on-hover button {
  display: block;
}
</style>
