<template>
  <div>
    <v-container>
      <page-title title="General Setting"></page-title>
      <v-row>
        <v-col>
          <v-card>
            <v-tabs v-model="tab">
              <v-tab v-for="(t, i) in tabs" :key="i">{{ t.title }}</v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
              <v-tab-item v-for="(t, i) in tabs" :key="i">
                <div>
                  <component :is="t.component"></component>
                </div>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import General from './components/General.vue';
import Module from './components/Module.vue';
import DataAccess from './components/DataAccess.vue';
export default {
  components: {
    General,
    Module,
    DataAccess,
  },

  data: () => ({
    tab: null,
    tabs: [
      { title: 'General', component: 'General' },
      { title: 'Module', component: 'Module' },
      { title: 'Data Access', component: 'DataAccess' },
    ]
  }),

  mounted() {
    this.requiredLogin();
  },
};
</script>